import { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { format, parseISO, addMinutes, differenceInMinutes } from 'date-fns';
import { de, enGB as en, fr, it } from 'date-fns/locale';
import _ from 'lodash';
import cx from 'classnames';

import AnswerList from 'components/AnswerList';
import Alert, { AlertType } from 'components/ui/Alert';
import AuctionStatusBox from './AuctionStatusBox';
import FAQ from 'components/ui/FAQ';
import Button from 'components/ui/Button';
import Countdown from 'components/ui/Countdown';
import Footer from 'components/Footer';
import AcceptModal from './Modals/AcceptModal';
import DeclineModal from './Modals/DeclineModal';
import RestartAuctionModal from './Modals/RestartAuctionModal';
import LowBidHintModal from './Modals/LowBidHintModal';
import Loading from 'components/Loading/Loading';

import {
  CONTRACT_PDF_URL,
  CHECKLIST_PDF_URL,
  DECISION_TIME_PERIOD,
} from 'constants/constants';
import useLowDemand from 'hooks/useLowDemand';
import {
  useAdditionalInfo,
  useAttributes,
  useAuctionMetrics,
  useDealerContacts,
} from 'hooks/queries';
import { useCmsData } from 'contexts/CmsDataProvider';
import {
  formatNumber,
  getAttributeTranslation,
  numberWithSeparator,
} from 'utils/utils';
import { AuctionStatus } from 'types/enum';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as RoadIcon } from 'assets/icons/road.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as BulbIcon } from 'assets/icons/bulb.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

type Props = {
  className?: string;
};

function getExtendedRemainingMinutes(endsAt: string) {
  const MAX_EXTENDED_TIME = 120 + 5;
  const targetDate = parseISO(endsAt);
  const targetDatePlusExtendedPeriod = addMinutes(
    targetDate,
    MAX_EXTENDED_TIME
  );
  const currentDate = new Date();
  const remainingMinutes = differenceInMinutes(
    targetDatePlusExtendedPeriod,
    currentDate
  );

  return remainingMinutes;
}

function getIsPendingWithLowBid(
  status: AuctionStatus,
  priceFrom: number,
  priceTo: number,
  highestBid: number
) {
  if (status !== AuctionStatus.PendingDecision || !highestBid) {
    return false;
  }

  const sellerAverage = Math.round((priceFrom + priceTo) / 2);

  if (sellerAverage > 5000 && sellerAverage <= 15000) {
    if (highestBid < (60 * sellerAverage) / 100) {
      return true;
    }
  } else if (sellerAverage > 15000 && sellerAverage <= 40000) {
    if (highestBid < (70 * sellerAverage) / 100) {
      return true;
    }
  } else if (sellerAverage > 40000) {
    if (highestBid < (75 * sellerAverage) / 100) {
      return true;
    }
  }

  return false;
}

const AuctionDasboard: React.FC<Props> = ({ className }) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false);
  const [showAditionalInfo, setShowAditionalInfo] = useState(false);
  const [isLowBidHintModalOpen, setIsLowBidHintModalOpen] = useState(false);
  const [isRepublishable, setIsRepublishable] = useState(false);

  const { data: cmsData, t, locale } = useCmsData();
  const {
    data: additionalInfo,
    submissionId = '',
    refetch,
  } = useAdditionalInfo();
  const { isLowDemand } = useLowDemand();

  const { data: dealerContacts, isLoading: isLoadingDealerContacts } =
    useDealerContacts(submissionId, {
      enabled: additionalInfo?.status === AuctionStatus.Accepted,
    });
  const { isLoading: isLoadingAuctionMetrics } = useAuctionMetrics(
    submissionId,
    {
      enabled: additionalInfo?.status === AuctionStatus.Active,
    }
  );

  const {
    vehicleInfo: {
      make,
      model,
      exactModel,
      firstRegistrationMonth,
      firstRegistrationYear,
      mileage,
      editedMileage,
      body,
    },
    auction: { counterOfferAccepted, endsAt, bids, highestBid },
    status,
    priceFrom,
    priceTo,
  } = additionalInfo!;
  const { data: attributes } = useAttributes();

  const toggleShowAditionalInfo = () => setShowAditionalInfo((prev) => !prev);

  const isPendingWithLowBid = useMemo(
    () => getIsPendingWithLowBid(status, priceFrom, priceTo, highestBid),
    [status, priceFrom, priceTo, highestBid]
  );
  const isAuctionEnded = useMemo(
    () => (endsAt ? new Date() >= new Date(endsAt) : false),
    [endsAt]
  );
  const endedWithNoBids =
    isAuctionEnded && status !== AuctionStatus.Active && !bids;
  const isDecisionButtonsDisabled =
    status === AuctionStatus.InReview ||
    status === AuctionStatus.Scheduled ||
    status === AuctionStatus.Active;
  const isDecisionButtonsVisible =
    status === AuctionStatus.InReview ||
    status === AuctionStatus.Scheduled ||
    status === AuctionStatus.Active ||
    (status === AuctionStatus.PendingDecision && !endedWithNoBids);

  const renderAlert = (status: AuctionStatus) => {
    const customStatus =
      isAuctionEnded && status === AuctionStatus.Active
        ? 'as24_extended'
        : endedWithNoBids
        ? 'as24_no_bids'
        : counterOfferAccepted
        ? 'as24_counter_offer_accepted'
        : counterOfferAccepted === false
        ? 'as24_counter_offer_declined'
        : status;

    const ALERT_TYPE: { [key: string]: AlertType } = {
      [AuctionStatus.InReview]: AlertType.Warning,
      [AuctionStatus.Active]: AlertType.Success,
      [AuctionStatus.PendingDecision]: AlertType.Warning,
      as24_extended: AlertType.Success,
      as24_counter_offer_accepted: AlertType.Success,
    };

    return (
      <Alert
        className="mb-12"
        title={t(`dashboard_alert_${customStatus.split('as24_').pop()}_title`)}
        description={t(
          `dashboard_alert_${customStatus.split('as24_').pop()}_desc`,
          {
            startDate: format(
              additionalInfo?.auction?.startsAt || Date.now(),
              'dd MMMM yyyy HH:mm',
              { locale: { de, en, fr, it }[locale] }
            ),
            extendedMinutes: getExtendedRemainingMinutes(
              additionalInfo?.auction?.endsAt || new Date().toISOString()
            ).toString(),
          }
        )}
        type={ALERT_TYPE[customStatus]}
      />
    );
  };

  const renderNextSteps = (status: AuctionStatus) => {
    const prefix =
      isLowDemand && (status === AuctionStatus.Declined || endedWithNoBids)
        ? 'dashboard_next_steps_low_demand_'
        : AuctionStatus.PendingDecision && endedWithNoBids
        ? 'dashboard_next_steps_no_bids_'
        : isPendingWithLowBid
        ? 'dashboard_next_steps_low_bid_'
        : `dashboard_next_steps_${status.split('as24_').pop()}_`;

    const nextSteps = cmsData!.content
      .filter((el) => el.key.startsWith(prefix))
      .map((el) => ({
        step: +(el.key.split(prefix).pop() || 0),
        content: el[locale],
      }));

    return _.sortBy(nextSteps, 'step').map(({ step, content }, index) => (
      <div className="mt-4 flex" key={index}>
        <div className="flex">
          <p className="bg-primary text-black rounded-full inline-flex justify-center items-center shrink-0 size-6 text-base font-bold">
            {step}
          </p>
          <div className="ml-2">{content}</div>
        </div>
      </div>
    ));
  };

  const questions = useMemo(() => {
    const questions = cmsData!.content
      .filter((el) => el.key.startsWith('dashboard_faq_item_'))
      .map((el) => ({ key: el.key, value: el[locale] }));

    const grouped = _.groupBy(questions, (item) => {
      const match = item.key.match(/dashboard_faq_item_(\d+)_/);
      return match ? match[1] : null;
    });

    return _.map(grouped, (group) => {
      const title =
        _.find(group, (item) => item.key.includes('_title'))?.value || '';
      const content =
        _.find(group, (item) => item.key.includes('_content'))?.value || '';
      return { title, content };
    });
  }, [cmsData, locale]);

  const renderDocs = () => {
    const contractUrl = new URL(CONTRACT_PDF_URL);
    const checklistUrl = new URL(CHECKLIST_PDF_URL);
    const {
      vehicleInfo: {
        make,
        model,
        exactModel,
        firstRegistrationMonth,
        firstRegistrationYear,
        mileage,
        vin,
        gear,
        hp,
      },
      contact: { firstName, lastName, address, phone },
    } = additionalInfo!;
    const { street, zip, city } = address!;

    contractUrl.searchParams.append('lang', locale);
    checklistUrl.searchParams.append('lang', locale);
    contractUrl.searchParams.append('seller_first_name', firstName);
    contractUrl.searchParams.append('seller_last_name', lastName);
    contractUrl.searchParams.append('seller_address', street);
    contractUrl.searchParams.append('seller_zip', zip);
    contractUrl.searchParams.append('seller_city', city);
    contractUrl.searchParams.append('seller_phone', phone);

    if (dealerContacts) {
      const name = dealerContacts.contactPerson.split(' ')[1];

      contractUrl.searchParams.append(
        'buyer_first_name',
        dealerContacts.contactPerson.split(' ')[1]
      );
      contractUrl.searchParams.append(
        'buyer_last_name',
        dealerContacts.contactPerson.split(name + ' ').pop() || ''
      );
      contractUrl.searchParams.append('buyer_address', dealerContacts.address);
      contractUrl.searchParams.append('buyer_zip', dealerContacts.zip);
      contractUrl.searchParams.append('buyer_city', dealerContacts.city);
      contractUrl.searchParams.append('buyer_phone', dealerContacts.phone);
    }

    contractUrl.searchParams.append('vehicle_brand', make);
    contractUrl.searchParams.append(
      'vehicle_model_type',
      `${model} ${exactModel}`
    );
    contractUrl.searchParams.append(
      'vehicle_first_registration_date',
      `${(firstRegistrationMonth + 1).toString().padStart(2, '0')}.
      ${firstRegistrationYear}`
    );
    contractUrl.searchParams.append(
      'vehicle_mileage',
      formatNumber(mileage, { price: false, hiddenFloat: false })
    );
    contractUrl.searchParams.append(
      'vehicle_gear_type',
      getAttributeTranslation({ key: 'gear', value: gear }, locale, attributes)
    );
    if (vin) {
      contractUrl.searchParams.append('vehicle_vin', vin);
    }
    if (hp) {
      contractUrl.searchParams.append(
        'vehicle_hp',
        formatNumber(hp, { price: false, hiddenFloat: false })
      );
    }

    const DOCUMENTS = [
      {
        name: t('dashboard_docs_contract_title'),
        url: contractUrl.toString(),
      },
      // {
      //   name: t('dashboard_docs_handover_title'),
      //   url: t('dashboard_docs_handover_url'),
      // },
      {
        name: t('dashboard_docs_checklist_title'),
        url: checklistUrl.toString(),
      },
    ];

    return DOCUMENTS?.map(({ name, url }, index) => (
      <a
        key={index}
        href={url}
        className="flex items-center w-fit mt-3 text-tertiary hover:underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <PdfIcon className="mr-1 size-6" />
        {name}
      </a>
    ));
  };

  if (isLoadingDealerContacts || isLoadingAuctionMetrics) {
    return <Loading />;
  }

  return (
    <>
      <AcceptModal
        isOpen={isAcceptModalOpen}
        onDismiss={() => setIsAcceptModalOpen(false)}
      />
      <DeclineModal
        isOpen={isDeclineModalOpen}
        onDismiss={() => setIsDeclineModalOpen(false)}
      />
      <RestartAuctionModal
        isOpen={isRestartModalOpen}
        onDismiss={() => setIsRestartModalOpen(false)}
        republishable={isRepublishable}
      />
      <LowBidHintModal
        isOpen={isLowBidHintModalOpen}
        onDismiss={() => setIsLowBidHintModalOpen(false)}
      />

      <div className={cx('flex flex-col', className)}>
        <div className="mt-6">
          {renderAlert(status)}
          <h1 className="text-2xl sm:text-[32px] leading-[40px] font-bold">
            {t('dashboard_title')}
          </h1>
        </div>
        <div className="mt-14 flex justify-between gap-10 flex-col lg:flex-row">
          <div className="max-w-[456px]">
            <img
              className="w-full max-w-[456px] rounded"
              src={additionalInfo!.photos[0].url}
              alt="car"
            />
            <h3 className="my-3 font-bold">
              {make} {model} {exactModel}{' '}
              {getAttributeTranslation(
                { key: 'bodyType', value: body },
                locale,
                attributes
              )}
            </h3>
            <div className="font-bold text-2xl leading-[30px]">
              {t('offer_min_price', {
                price: formatNumber(additionalInfo!.desiredPrice || 0, {
                  price: true,
                  hiddenFloat: true,
                }),
              })}
            </div>
            <div className="mt-3 flex items-center gap-x-1">
              <CalendarIcon className="size-4" />
              {(firstRegistrationMonth + 1).toString().padStart(2, '0')}.
              {firstRegistrationYear}
            </div>
            <div className="mt-1 flex items-center gap-x-1">
              <RoadIcon className="size-4" />
              {numberWithSeparator(editedMileage || mileage)} {t('km')}
            </div>
            <div
              className="mt-6 flex items-center text-tertiary gap-x-2.5 cursor-pointer"
              onClick={toggleShowAditionalInfo}
            >
              {!showAditionalInfo ? (
                <ArrowRightIcon className="size-6 rotate-90" />
              ) : (
                <ArrowRightIcon className="size-6 -rotate-90" />
              )}
              {!showAditionalInfo
                ? t('offer_show_more_info_link')
                : t('offer_hide_more_info_link')}
            </div>
            <AnimatePresence>
              {showAditionalInfo && (
                <motion.div
                  className="overflow-hidden"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <AnswerList
                    answers={additionalInfo!.answers}
                    additionalDescription={
                      additionalInfo!.additionalDescription?.[locale]
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="w-full lg:w-[610px]">
            <AuctionStatusBox
              isPendingWithLowBid={isPendingWithLowBid}
              openLowBidHintModal={() => setIsLowBidHintModalOpen(true)}
              buyer={dealerContacts}
            />
            {isDecisionButtonsVisible && (
              <div className="mt-6 pb-4 flex flex-col gap-y-3">
                <Button
                  label={
                    status === AuctionStatus.PendingDecision ? (
                      <>
                        {t('dashboard_accept_btn')} (
                        <Countdown
                          date={
                            new Date(
                              new Date(endsAt!).getTime() + DECISION_TIME_PERIOD
                            )
                          }
                          onComplete={refetch}
                        />
                        )
                      </>
                    ) : (
                      t('dashboard_accept_btn')
                    )
                  }
                  onClick={() => setIsAcceptModalOpen(true)}
                  icon={null}
                  isDisabled={isDecisionButtonsDisabled}
                />
                <Button
                  outline
                  label={t('dashboard_decline_btn')}
                  onClick={() => setIsDeclineModalOpen(true)}
                  icon={null}
                  isDisabled={isDecisionButtonsDisabled}
                />
              </div>
            )}

            <div className="border rounded border-light p-6 mt-8">
              <p className="text-xl font-bold">{t('next_steps_auction')}</p>
              {renderNextSteps(status)}
              {status === AuctionStatus.Declined || endedWithNoBids ? (
                <div className="pl-8 mt-4 flex flex-col">
                  {isLowDemand && (
                    <div className="flex items-center gap-2 mb-4">
                      <input
                        type="checkbox"
                        id="republishable"
                        className="cursor-pointer"
                        defaultChecked={isRepublishable}
                        onClick={() => setIsRepublishable(!isRepublishable)}
                      />
                      <label htmlFor="republishable" className="cursor-pointer">
                        {t('dashboard_republishable_label')}
                      </label>
                    </div>
                  )}
                  <Button
                    className="w-full lg:w-fit"
                    label={t('dashboard_restart_btn')}
                    onClick={() => setIsRestartModalOpen(true)}
                    icon={null}
                  />

                  <h4 className="mt-12 font-bold leading-5">
                    {t('dashboard_better_price_title')}
                  </h4>
                  <a
                    href={t('dashboard_better_price_link_href')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="my-2 w-fit text-tertiary hover:underline"
                  >
                    {t('dashboard_better_price_link_text')}
                  </a>
                  <p>{t('dashboard_better_price_content')}</p>
                </div>
              ) : (
                status !== AuctionStatus.Accepted &&
                status !== AuctionStatus.PendingCounterOffer && (
                  <div className="flex mt-6 text-light-shade">
                    <BulbIcon className="size-6 shrink-0" />
                    <p className="ml-3">
                      {status === AuctionStatus.InReview ||
                      status === AuctionStatus.Scheduled
                        ? t('dashboard_timing_rules_hint')
                        : t('dashboard_decision_rules_hint')}
                    </p>
                  </div>
                )
              )}
            </div>

            <div className="border rounded border-light p-6 mt-8">
              <p className="text-xl font-bold">{t('dashboard_docs_title')}</p>
              <div className="mt-4">{renderDocs()}</div>
            </div>
          </div>
        </div>
      </div>
      <FAQ
        className="mt-44"
        title={t('dashboard_faq_title')}
        questions={questions}
      />
      <Footer className="mt-[112px]" />
    </>
  );
};

export default AuctionDasboard;
