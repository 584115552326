import { forwardRef, useEffect, useState } from 'react';
import {
  Field,
  Label,
  Input as InputComponent,
  InputProps,
} from '@headlessui/react';
import { AnimatePresence } from 'framer-motion';
import cx from 'classnames';

import Fade from 'components/ui/Fade';
import Tooltip from 'components/ui/Tooltip';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = InputProps & {
  className?: string;
  label: string;
  value: string | number | null;
  tooltip?: string;
  hint?: string;
  invalid?: boolean;
  invalidMsg?: string;
  validator?: (value: string | number) => boolean;
  onChange: (value: string | number | null) => any;
};

const formatNumber = (number: number | null | undefined) => {
  if (!number && number !== 0) {
    return '';
  }

  let result = new Intl.NumberFormat('de-ch', {
    maximumFractionDigits: 2,
  }).format(number);

  return result;
};

const inputCls =
  'block w-full px-2 py-[11px] placeholder:text-border border border-border rounded focus:outline-none focus:ring-2 focus:ring-tertiary-tint transition-colors';
const invalidCls = 'border-danger border-[1.5px]';

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      tooltip,
      hint,
      value,
      onChange,
      invalid = false,
      invalidMsg,
      validator,
      ...props
    },
    ref
  ) => {
    const [numericTextValue, setNumericTextValue] = useState('');

    useEffect(() => {
      if (value) {
        handleNumericChange(value.toString());
      }
    }, [value]); // eslint-disable-line

    const parseNumber = (val: string) => parseInt(val.replace(/’/g, ''), 10);
    const handleNumericChange = (value: any) => {
      const numericValue = parseNumber(value);
      const formattedValue = formatNumber(numericValue);
      setNumericTextValue(formattedValue);
    };

    const input =
      props.type === 'number' ? (
        <InputComponent
          ref={ref}
          className={cx(inputCls, invalid && invalidCls)}
          {...props}
          type="text"
          value={numericTextValue}
          onChange={(e) => {
            handleNumericChange(e.target.value);
            onChange(parseNumber(e.target.value) || null);
          }}
        />
      ) : (
        <InputComponent
          ref={ref}
          className={cx(inputCls, invalid && invalidCls)}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      );

    return (
      <div className={className}>
        <Field>
          <Label className="flex items-center font-bold mb-0.5">
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoIcon className="ml-1 size-5 cursor-pointer" />
              </Tooltip>
            )}
          </Label>
          {input}
          <AnimatePresence>
            {invalid && invalidMsg && (
              <Fade exit={false}>
                <p className="mt-0.5 text-sm text-danger">{invalidMsg}</p>
              </Fade>
            )}
          </AnimatePresence>
          {hint && <p className="mt-0.5 text-sm text-border">{hint}</p>}
        </Field>
      </div>
    );
  }
);

export default Input;
