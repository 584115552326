import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useUpdateEffect } from 'react-use';
import parse from 'html-react-parser';
import Refiner from 'refiner-js';

import Header from 'components/Header';
import Loading from 'components/Loading/Loading';
import HeadingSection from 'components/HeadingSection';
import Fade from 'components/ui/Fade';
import Tabs, { Tab } from 'components/Tabs';
import Questions from 'components/views/Questions/Questions';
import RegistrationDoc from 'components/views/RegistrationDoc';
import Photos from 'components/views/Photos';
import MinPrice from 'components/views/MinPrice/MinPrice';
import Offer from 'components/views/Offer';
import AuctionDasboard from 'components/views/AuctionDashboard/AuctionDashboard';
import NotFound from 'components/views/404';
import Alert, { AlertType } from 'components/ui/Alert';

import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo, useAttributes } from 'hooks/queries';
import { IS_PRODUCTION } from 'constants/constants';
import { getTabsStatus, scrollToTop } from 'utils/utils';
import { AuctionStatus } from 'types/enum';

const App: React.FC = () => {
  const {
    data: cmsData,
    t,
    locale,
    isLoading: isLoadingCmsData,
  } = useCmsData();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState('');

  const {
    data: additionalInfo,
    isLoading: isLoadingAdditionalInfo,
    submissionId,
  } = useAdditionalInfo();
  const { isLoading: isLoadingAttributes } = useAttributes();

  useEffect(() => {
    if (IS_PRODUCTION && locale) {
      Refiner('setProject', '752daab0-4e52-11ef-845f-3d210da4bb4c');
      Refiner('setLocale', locale);
    }
  }, [locale]);

  useEffect(() => {
    if (additionalInfo?.contact) {
      Refiner('identifyUser', {
        id: submissionId,
        email: additionalInfo.contact.email,
        name: `${additionalInfo.contact.firstName} ${additionalInfo.contact.lastName}`,
      });
    }
  }, [additionalInfo?.contact, submissionId]);

  useUpdateEffect(() => {
    if (!isLoadingCmsData && !isLoadingAdditionalInfo) {
      const {
        disabledRegDoc,
        disabledPhotos,
        disabledMinPrice,
        disabledOffer,
      } = getTabsStatus(cmsData, additionalInfo);

      const updTabs = [
        {
          id: 'questions',
          label: t('tab_questions'),
          disabled: false,
          completed: !disabledRegDoc,
        },
        {
          id: 'regDoc',
          label: t('tab_reg_doc'),
          disabled: disabledRegDoc,
          completed: !disabledPhotos,
        },
        {
          id: 'photos',
          label: t('tab_photos'),
          disabled: disabledPhotos,
          completed: !disabledMinPrice,
        },
        {
          id: 'minPrice',
          label: t('tab_min_price'),
          disabled: disabledMinPrice,
          completed: !disabledOffer,
        },
        {
          id: 'offer',
          label: t('tab_offer'),
          disabled: disabledOffer,
          completed: false,
        },
      ];

      setTabs(updTabs);
      activeTab === '' &&
        setActiveTab(
          [...updTabs].find((tab) => !tab.completed)?.id || 'questions'
        );
    }
  }, [isLoadingCmsData, isLoadingAdditionalInfo, additionalInfo]);

  const onPrevTab = () => {
    const curIndex = tabs.findIndex((el) => el.id === activeTab);

    if (!tabs[curIndex - 1]) {
      return;
    }

    setActiveTab(tabs[curIndex - 1].id);
    scrollToTop();
  };
  const onNextTab = () => {
    const curIndex = tabs.findIndex((el) => el.id === activeTab);

    if (!tabs[curIndex + 1]) {
      return;
    }

    const updTabs = [...tabs];
    updTabs[curIndex + 1].disabled = false;
    setTabs(updTabs);
    setActiveTab(updTabs[curIndex + 1].id);
    scrollToTop();
  };

  if (
    isLoadingCmsData ||
    isLoadingAdditionalInfo ||
    isLoadingAttributes ||
    !tabs.length
  ) {
    return (
      <Fade>
        <Loading />
      </Fade>
    );
  }

  if (!additionalInfo || additionalInfo?.widgetKey !== 'as24') {
    return (
      <>
        <Header />
        <NotFound />
      </>
    );
  }

  if (additionalInfo.status !== AuctionStatus.Incomplete) {
    return (
      <>
        <Helmet>
          <html lang={locale} />
          <title>{t('header_title')}</title>
        </Helmet>
        <Header />
        <AuctionDasboard className="px-6" />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <html lang={locale} />
        <title>{t('header_title')}</title>
      </Helmet>
      <Header />
      <div className="px-6">
        {activeTab === tabs[4].id && (
          <Alert
            className="mt-6 mb-8"
            title={t('offer_alert_publication_hint_title')}
            description={parse(t('offer_alert_publication_hint_desc'))}
            type={AlertType.Default}
          />
        )}
        <HeadingSection
          className="mt-6 mb-[38px] sm:mb-6"
          vehicleInfo={additionalInfo.vehicleInfo}
        />
        <Tabs activeTab={activeTab} onChange={setActiveTab} tabs={tabs} />
      </div>

      {activeTab === tabs[0].id && (
        <Fade>
          <Questions className="mt-8 px-6" goNext={onNextTab} />
        </Fade>
      )}
      {activeTab === tabs[1].id && (
        <Fade>
          <RegistrationDoc
            className="mt-8 px-6"
            goBack={onPrevTab}
            goNext={onNextTab}
          />
        </Fade>
      )}
      {activeTab === tabs[2].id && (
        <Fade>
          <Photos className="mt-8 px-6" goBack={onPrevTab} goNext={onNextTab} />
        </Fade>
      )}
      {activeTab === tabs[3].id && (
        <Fade>
          <MinPrice
            className="mt-8 px-6 sm:mt-16"
            goBack={onPrevTab}
            goNext={onNextTab}
          />
        </Fade>
      )}
      {activeTab === tabs[4].id && (
        <Fade>
          <Offer className="mt-8 px-6 sm:mt-16" goBack={onPrevTab} />
        </Fade>
      )}
    </>
  );
};

export default App;
