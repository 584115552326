import { useCmsData } from 'contexts/CmsDataProvider';
const useLowDemand = () => {
  const { data: cmsData } = useCmsData();

  const currentDate = new Date();
  const isLowDemand =
    cmsData?.republishing?.active &&
    cmsData.republishing.start &&
    cmsData.republishing.end &&
    currentDate >= new Date(cmsData.republishing.start) &&
    currentDate <= new Date(cmsData.republishing.end);

  return { isLowDemand };
};

export default useLowDemand;
