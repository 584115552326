import { Textarea as TextareaComponent } from '@headlessui/react';
import { AnimatePresence } from 'framer-motion';

import Tooltip from 'components/ui/Tooltip';
import Fade from 'components/ui/Fade';

import { useCmsData } from 'contexts/CmsDataProvider';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

type Props = {
  label: string;
  placeholder?: string;
  value: string | undefined;
  maxLength?: number;
  tooltip?: string;

  onChange: (val: string | undefined) => any;
};

const Textarea: React.FC<Props> = ({
  label,
  placeholder,
  value,
  maxLength,
  tooltip,
  onChange,
}) => {
  const { t } = useCmsData();

  return (
    <div className="flex flex-col justify-between">
      <div className="min-h-10 mb-2 font-bold leading-5">
        {label}
        {tooltip && (
          <Tooltip title={tooltip} color="var(--color-background)">
            <InfoIcon className="inline ml-1 size-5 cursor-pointer" />
          </Tooltip>
        )}
      </div>
      <div className="relative mb-2 !h-[115px] border border-light rounded">
        <TextareaComponent
          className="w-full h-full px-3.5 placeholder:text-border border-y-[14px] border-background rounded focus:outline-none focus:ring-2 focus:ring-tertiary-tint transition-colors leading-5 resize-none"
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      </div>
      <AnimatePresence>
        {value && maxLength && (
          <Fade transition={{ ease: 'easeInOut', duration: 0.15 }}>
            <div className="relative block w-full h-1.5 border border-border-light rounded-full">
              <div
                className="aboslute top-0 left-0 h-full w-10 bg-primary transition-all"
                style={{ width: `${(value.length / maxLength) * 100}%` }}
              />
            </div>
            <span className="mt-0.5 flex justify-end text-sm text-border">
              {t('textarea_limit_hint', {
                left: (maxLength - value.length).toString(),
              })}
            </span>
          </Fade>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Textarea;
